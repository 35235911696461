import React, { Component } from 'react';
import CodeHighlighter from './code-highlighter';
import "./css/cs-highlight.css";

const regexes = [

    { className: "key", r: new RegExp(/(class)/g) }, // key
    { className: "key", r: new RegExp(/(\bnew\b)/) }, // key
    { className: "key", r: new RegExp(/(\btrue\b)/) }, // key
    { className: "key", r: new RegExp(/(\bfalse\b)/) }, // key
    { className: "key", r: new RegExp(/(this)/) }, // key
    { className: "key", r: new RegExp(/(struct)/) }, // key
    { className: "key", r: new RegExp(/(public)/g) }, // key
    { className: "key", r: new RegExp(/(private)/g) }, // key
    { className: "key", r: new RegExp(/(void)/g) }, // key
    { className: "key", r: new RegExp(/(extends)/g) }, // key
    { className: "key", r: new RegExp(/(Vector3)/g) }, // key
    { className: "key", r: new RegExp(/(Vector2)/g) }, // key
    { className: "key", r: new RegExp(/(\bfloat\b)/g) }, // key
    { className: "key", r: new RegExp(/(\bint\b)/g) }, // key
    { className: "key", r: new RegExp(/(bool)/g) }, // key
    { className: "key", r: new RegExp(/(null)/g) }, // key
    { className: "key", r: new RegExp(/(\bref\b)/g) }, // key
    
    { className: "function", r: new RegExp(/(\w+)(\(.*\))/g) }, // any function call
    { className: "function", r: new RegExp(/(GetComponent)/g) }, // built in function
    { className: "function", r: new RegExp(/(AddComponent)/g) }, // built in function
    
    
    { className: "variable", r: new RegExp(/(Scene)/g) }, // built in shader variable
    { className: "variable", r: new RegExp(/(\breturn\b)/g) }, // key
    { className: "variable", r: new RegExp(/(\bif\b)/g) }, // key
    { className: "variable", r: new RegExp(/(\bfor\b)/g) }, // key
    { className: "variable", r: new RegExp(/(\belse\b)/g) }, // key
    
    { className: "type", r: new RegExp(/(MonoBehaviour)/g) }, // built in type
    { className: "type", r: new RegExp(/(List)/g) }, // key
    // { className: "type", r: new RegExp(/(Triangle)/g) }, // built in type
    { className: "type", r: new RegExp(/(Range)/g) }, // built in type
    { className: "type", r: new RegExp(/(GameObject)/g) }, // built in type
    { className: "type", r: new RegExp(/(MeshFilter)/g) }, // built in type
    { className: "type", r: new RegExp(/(MeshSlicer)/g) }, // built in type
    
    { className: "comment-line", r: new RegExp(/(\/\/.*)/g) }, // comment
    { className: "comment-line", r: new RegExp(/(\/\*\*.*\*\/)/gm) }, // comment
    // { className: "type", r: new RegExp(/(\w+)\s\w+\s=.*/g) }, // built in type
        
] 

export default class CSharpHighlighter extends Component {
    constructor(props) {
        super(props)
    }

    render () {

        const { code, redList, greenList } = this.props;

        return <CodeHighlighter
                    prefix={"cs"}
                    code={code}
                    regexes={regexes}
                    redList={redList}
                    greenList={greenList} 
                /> 
    }
}